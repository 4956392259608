import React, { useState } from 'react'
import { Link } from 'react-router-dom';

function Navbar({active}) {



  var act = "null"
  act = active;

  var isClicked = true;

  console.log(active);


  var counter = 0;
  function setLines(){
    if (document.documentElement.style.getPropertyValue('--menu-display')=='none'){
      console.log("block")
      document.documentElement.style.setProperty('--menu-display', 'block');
    }
    else{
      document.documentElement.style.setProperty('--menu-display', 'none');
      console.log("none")
    }
  }

  function closeMenu(){
    document.documentElement.style.setProperty('--menu-display', 'none');
    console.log("none")
  }

  


  return (
    <>
    <header class='header'>
        <Link to="/"><img src={process.env.PUBLIC_URL +`/images/logo.png`} alt='Logo' class='logo'/></Link>
        <input class='menu-btn' type='checkbox' id='menu-btn'/>
        <label class='menu-icon fas fa-bars' for='menu-btn' onClick={() => setLines()}><span class='navicon'></span><span class='navicon'></span></label>
        
        <ul class='menu' onClick={closeMenu()}>
          {act!="Home" ? <li><Link to="/" class='headerlia' >Home</Link></li> : <li><Link to="/" class='highlight'>Home</Link></li>}
          {act!="Vermietung" ? <li><Link to="/vermietung" class='headerlia'>Vermietung</Link></li> : <li><Link to="/vermietung" class='highlight'>Vermietung</Link></li>}
          {act!="Ueberuns" ? <li><Link to="/ueberuns" class='headerlia'>Über uns</Link></li> : <li><Link to="/ueberuns" class='highlight'>Über uns</Link></li>}
          {act!="Kontakt" ? <li><Link to="/kontakt" class='headerlia'>Kontakt</Link></li> : <li><Link to="/kontakt" class='highlight'>Kontakt</Link></li>}
        </ul>

        {/*
        <div class='decoration_nav1' id='deco'></div>
        <div class='decoration_nav2' id='decoo'></div>
        <div class='decoration_nav3' id='decooo'></div>
        */}

      </header>
    </>
  )
}

export default Navbar;