import React from 'react'
import { useState, useEffect, useLayoutEffect } from 'react';

import {BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
import Navbar from '../components/Navbar';
import 'react-slideshow-image/dist/styles.css'
import { Slide } from 'react-slideshow-image';


function Ueberuns() {

  useLayoutEffect(() => {
    window.scrollTo(0, 0)
});



  const properties = {
    duration: 2000,
    autoplay: true,
    infinite: true,
    pauseOnHover: false,
    cssClass: "height: 50%",
    transitionDuration: 500,
    prevArrow: <button><a class="prev">&#10094;</a></button>,
    nextArrow: <button><a class="next">&#10095;</a></button>,
  };

  const srcImages = ["./images/lift4m.png","./images/boomtonedj_neu.png"];



  return (
        
        


    <>
     <Navbar active={"Ueberuns"}></Navbar>


    <div class="placeholder"></div>
    <p class="slidetitel">Über uns</p>


      

    
    
<section>



<article className='uebermargin'>
  <p class="p">
    <h1>
    Wir sind ein kleines, aber schlagkräftiges Team. Flexibel und Umsetzungsstark engagieren wir uns für dein Event.
    </h1>
  </p>
</article>

<article>
      <p class="teamtitel">
          Das Team
      </p>
      <div class="teamdeco"></div>
</article>

</section>





<section class="">

<article class="">
  <img src="./images/team.png" alt="Team" class="picUeber"/>
  <h4 class="h4Ueber">
    <div class="titelUeber">links Noah Rossel, rechts Micha Hösle</div> 
  </h4>
</article>

</section>



    </>
    
  )
}

export default Ueberuns;