import React from 'react'
import { useState, useEffect, useLayoutEffect } from 'react';

import {BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
import Navbar from '../components/Navbar';
import 'react-slideshow-image/dist/styles.css'
import { Slide } from 'react-slideshow-image';
import Iframe from 'react-iframe'



function Kontakt() {

  useLayoutEffect(() => {
    window.scrollTo(0, 0)
});


  const properties = {
    duration: 2000,
    autoplay: true,
    infinite: true,
    pauseOnHover: false,
    transitionDuration: 500,
    cssClass: "height: 50%",
    prevArrow: <button><a class="prev">&#10094;</a></button>,
    nextArrow: <button><a class="next">&#10095;</a></button>,
  };

  const srcImages = ["./images/lift4m.png","./images/boomtonedj_neu.png", "./images/tourhazer.png"];



  return (
        
        


    <>
     <Navbar active={"Kontakt"}></Navbar>


    <div class="placeholder"></div>
    <p class="slidetitel">Kontakt</p>


      


    
<section>

<article class="placeholder4 kntmargin">
      <p class="kontakttitel">
        So kannst du uns erreichen:
      </p>
      <div class="kontaktdeco"></div>
      <div class="kontakttext">
        <p>Eventindustry GmbH</p>
        <p>Im Luchli 1</p>
        <p>CH 3110 Münsingen</p>
        <p>E-Mail: <a class="kontlink" href="mailto:info@eventindustry.ch">info@eventindustry.ch</a></p>
        <p>Whatsapp: <a class="kontlink" href="tel:+41799393477">+41 79 939 34 77</a></p>
        <p>Telefon: <a class="kontlink" href="tel:+41799393477">+41 79 939 34 77</a></p>
        <p>Nachrichten: <a class="kontlink" href="sms:+41799393477">+41 79 939 34 77</a></p>
    </div>
</article>


<article class="placeholder4">
  <p class="kontakttitel2">
      Unser Lager befindet sich in:
  </p>
  <div class="kontaktdeco"></div>
  <div class="kontakttext">
    <p>Dorfmattweg 10</p>
    <p>CH 3110 Münsingen</p>
</div>
</article>



</section>

<section>
  <article>
    <Iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2293.5455662647573!2d7.556152170910857!3d46.87319738091568!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x478e4a115f05242d%3A0x7809089ab08f8acc!2sDorfmattweg%2010%2C%203110%20M%C3%BCnsingen!5e0!3m2!1sde!2sch!4v1712688805953!5m2!1sde!2sch" width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy"></Iframe>
  </article>
</section>


    </>
    
  )
}

export default Kontakt;