import React from 'react'
import Data from "../bilddata.json";

import { useState, useEffect, useLayoutEffect } from 'react';
import {BrowserRouter as Router, Routes, Route, Link, useParams } from 'react-router-dom';
import 'react-slideshow-image/dist/styles.css'
import { Slide } from 'react-slideshow-image';
import DetailSlideshow from '../components/DetailSlideshow';




function Bilddetailansicht({id}) {

  useLayoutEffect(() => {
    window.scrollTo(0, 0)
});


  const { paramsId } = useParams();

  var par = 0;
  par = parseInt(paramsId);
  console.log("par " + par);

  const [item, setItem] = useState();
  //var itemId;


  var foundObj;
  /*json Data Variables*/
  var title;
  var img = "keinbild";
  var price;
  var description;
  var table = [];
  var images = [];

  var isImages;
  var isTable;
  /*-------------------*/

  var tableMapCounter = 1;
  var firstItem;

  searchItem(par);
  function searchItem(id){
    var found = Data.find(post => {
      return post.id === id;
    });

    title = found.title;
    if(found.img != ""){
      img = found.img;
    }
    price = found.price;
    description = found.description;
    
    if(found.hasOwnProperty('table')){
      var tableLength = Object.keys(found.table[0]).length;
      //console.log(tableLength);
      

        found.table.map(item =>{
          for(var i = 1; i < tableLength+1; i++){
            //console.log(item)
            table.push(item[i])
          }
          
          /*
          table.push(item[jsonTableId])
          console.log("das isch es item "+ item)
          jsonTableId++;
          */
        })
        isTable = true;
        /*
        console.log("Table array: " + table);
        console.log(found.table[0])
        console.log(found.table[0].length)
        console.log(table)
        */

        
      }
      else{
        isTable = false;
      }

      if(found.hasOwnProperty('images')){
          foundObj = found;
          isImages = true;
        }
  
        else{
          isImages = false;
        }
      }


    
      
  

    //console.log("datailansicht id: " + id);
    //itemId = id;
    
  


  /*
  
  { Data.map(post => {
          return(
            <>
               {searchItem(id)}

            </>
          )
        })}

  
  */ 

        /*
        
         <h1>Detailansicht mit id: {paramsId}</h1>
      {console.log("Detailsansicht id wert: " + par)}

      <div>
        {searchItem(par)}
      </div>

      <div>
        {list.map(item =>{
          console.log("list.map: " + item);
          <p>Diese sind noch Manuell eingegeben</p>
          return(
            <>
              <p>{item}</p>
            </>
          )
        })}
      </div>

      <p>Parameter der URL: {paramsId}</p>

      <p>Hello Hello</p>
        */


        //const [tablecounter, setTableCounter] = useState(false);
  var tablecounter = false;
  var counter = 1;


  const color = getComputedStyle(document.documentElement).getPropertyValue('--table-display');

  useEffect(() => {

  }, []);

  var newColor = 'table-row';

  function setColor (){
    counter++;
    if (counter % 2){
      newColor = 'none'
      document.documentElement.style.setProperty('--table-display', newColor);
    }
    else{
      newColor = 'table-row'
      document.documentElement.style.setProperty('--table-display', newColor);
    }
  }


  return (
    <>
     


      <div className='placeholder'></div>


      <section>
      <article className='item_vermietung'>
        <h5>{title}</h5>

        {isImages && <DetailSlideshow found={foundObj}/>}
     
        {!isImages && <img src={process.env.PUBLIC_URL +`/images/${img}.png`} alt='Beamer-Bild' class='vermietung_produkt'/> }
        
      </article>

      <article className='price'>
        <div className='mietpreis'>
          <h6>
            Mietpreis:
          </h6>
        </div>

        <p className='p1'>
          CHF {price} / Tag
        </p>

        <div className='spaceing'>
          <p className='p2'>
            Bei Interesse <Link to="/kontakt">kontaktiere</Link> uns!
          </p>
        
      </div>

      </article>

      <article>
        <h6>
          Beschreibung:
        </h6>
        <p className='p3'>
          {description}
        </p>
      </article>

      {isTable &&
      <article>
        <table className='kontTable'>

          <thead onClick={() => setColor()}>
            <tr className='kontth'>
              <th className='kontth' colSpan={2}>Spezifikationen</th>
            </tr>
          </thead>

          <tbody>
              {table.map(item =>{
                tableMapCounter++;
                if(tableMapCounter%2){/*Zweiter Druchlauf*/
                return(
                  <>
                    <tr className='tableDisplay'>
                      <td className='konttd'>{firstItem}</td>
                      <td className='konttd_2'>{item}</td>
                    </tr>
                  </>
                )
                }
                else{/*Erster Druchlauf*/
                  firstItem = item;
                }
              })}
            </tbody>
        </table>
      </article>
      }

    {/*<img src={process.env.PUBLIC_URL + "/images/keinbild.png"} alt='Beamer-Bild' class=''/>*/}

    

      </section>

    </>
  )
}

export default Bilddetailansicht;