import React from 'react'
import Data from "../lichtdata.json";
import { useState, useEffect } from 'react';
import {BrowserRouter as Router, Routes, Route, Link, useParams } from 'react-router-dom';
//import axios from 'axios'
//import { useLocation } from 'react-router-dom'


function Detailansicht({id}) {

  

  const { paramsId } = useParams();

  var par = 0;
  par = parseInt(paramsId);
  console.log("par " + par);

  //const location = useLocation()
  //const { from } = location.state

  //console.log(from);

  const [item, setItem] = useState();
  var list = [];
  //var itemId;


  function searchItem(id){
    var found = Data.find(post => {
      return post.id === id;
    });
    
    console.log(found.title);
    list.push(found.title, found.img);
    console.log(list);


    //console.log("datailansicht id: " + id);
    //itemId = id;
    
  }


  /*
  
  { Data.map(post => {
          return(
            <>
               {searchItem(id)}

            </>
          )
        })}

  
  */ 




  return (
    <>
      <h1>Detailansicht mit id: {paramsId}</h1>
      {console.log("Detailsansicht id wert: " + par)}

      <div>
        {searchItem(par)}
      </div>

      <div>
        {list.map(item =>{
          console.log("list.map: " + item);
          <p>Diese sind noch Manuell eingegeben</p>
          return(
            <>
              <p>{item}</p>
            </>
          )
        })}
      </div>

      <p>Parameter der URL: {paramsId}</p>

    </>
  )
}

export default Detailansicht;