import React from 'react'
import { useState, useEffect, useLayoutEffect } from 'react';

import {BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
import Navbar from '../components/Navbar';
import 'react-slideshow-image/dist/styles.css'
import { Slide } from 'react-slideshow-image';


function Impressum() {

    useLayoutEffect(() => {
        window.scrollTo(0, 0)
    });
    
 


  const properties = {
    duration: 1000,
    autoplay: true,
    infinite: true,
    pauseOnHover: false,
    cssClass: "height: 50%",
    transitionDuration: 500,
    prevArrow: <button><a class="prev">&#10094;</a></button>,
    nextArrow: <button><a class="next">&#10095;</a></button>,
  };

  const srcImages = ["../images/keinbild.png", "../images/lift4m.png","../images/boomtonedj_neu.png", "../images/beamer_neu.png"];



  return (
        
        


    <>
        
        <div class="placeholder"></div>
        
        <p class="slidetitel">Impressum</p>

        <div class="placeholder"></div>
        

     

        <section>



        <article class="placeholder4">
                <p class="kontakttitel">
                    Kontaktdaten:
                </p>
                <div class="kontaktdeco"></div>
                <div class="kontakttext">
                    <p>Eventindustry GmbH</p>
                    <p>Im Luchli 1</p>
                    <p>CH 3110 Münsingen</p>
                    <p>E-Mail: <a class="kontlink" href="mailto:info@eventindustry.ch">info@eventindustry.ch</a></p>
                    <p>Telefon: +41 79 939 34 77</p>

                </div>
            </article>

            <h1>Urheberrechte</h1>
            <article>
            <h2 className='impressTxt'>
                Die Uhrheberrechte von allen Textinhalten, Dateien und einigen Bildern gehören ausschliesslich Eventindustry.
                Für die Verwendung der Textinhalten, Dateien und Bilder welche Eventindustry gehören, ist eine schriftliche Zustimmung des Urheberrechtsträgers im Voraus einzuholen.
            </h2>
            </article>
            <h1>Haftungsausschluss</h1>
            <article>
            <h2 className='impressTxt'>
                Verweise und Links auf Webseiten Dritter liegen ausserhalb unseres Verantwortungsbereichs. 
                Es wird jegliche Verantwortung für solche Webseiten abgelehnt. 
                Der Zugriff und die Nutzung solcher Webseiten erfolgen auf eigene Gefahr des jeweiligen Nutzers.
            </h2>
            </article>

        </section>



  



    </>
    
  )
}

export default Impressum;