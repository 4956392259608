import React from 'react'
import { Link } from 'react-router-dom';

function Footer() {
  return (
    <>
    
        <footer>
            <p>Kontaktdaten:</p>
            <div class='decoration'></div>

            <ul class='kontaktdaten'>
                <li>Eventindustry GmbH</li>
                <li>CH 3110 Münsingen</li>
                <li>Tel: +41 79 939 34 77</li>
                <li>E-Mail: info@eventindustry.ch</li>
            </ul>

            <table>
                <tr>
                <th><Link to="/kontakt">Kontakt</Link></th>
                <th><Link to="/impressum">Impressum</Link></th>
                </tr>
            </table>

            <div class='decoration_2'></div>

            <ul class='kontaktlink'>
                <li><Link to="/kontakt">Kontakt</Link></li>
                <li><Link to="/impressum">Impressum</Link></li>
            </ul>

            <div class='decoration_3'></div>
            <div class='decoration_4'></div>

        </footer>
        <div className='createdby'>
            © 2024 Copyright | Created by Jonas Hafner
        </div>
    </>
  )
}

export default Footer;