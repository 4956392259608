import React from 'react'
import { useState, useEffect, useLayoutEffect } from 'react';

import {BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
import Navbar from '../components/Navbar';
import 'react-slideshow-image/dist/styles.css'
import { Slide } from 'react-slideshow-image';


function Vermietung() {

  useLayoutEffect(() => {
    window.scrollTo(0, 0)
});



  const properties = {
    duration: 2000,
    autoplay: true,
    infinite: true,
    pauseOnHover: false,
    cssClass: "height: 50%",
    transitionDuration: 500,
    prevArrow: <button><a class="prev">&#10094;</a></button>,
    nextArrow: <button><a class="next">&#10095;</a></button>,
  };

  const srcImages = ["./images/lift4m.png","./images/boomtonedj_neu.png", "./images/tourhazer.png","./images/presonus24.png"];



  return (
        
        


    <>
     <Navbar active={"Vermietung"}></Navbar>




    <div class="placeholder"></div>
    <p class="slidetitel">Vermietung</p>


      

<div class="slideshow-container">

  <div style={{ width: "100%", height: "auto" }}>
    <Slide {...properties}>
      {srcImages.map((each, index) => (
        <img
          style={{ objectFit: "contain", width: "100%" }}
          src={each}
          key={index}
          alt={"Bild: "+ index}
        />
      ))}
    </Slide>
  </div>

</div>

          
          
      <section>

      <article>
        <h1>
          Schau dich in unserem Mietpark um. Wenn du das passende Produkt nicht gefunden hast, darfst du dich gerne bei uns melden, wir finden eine Lösung für dich.

        </h1>
      </article>

      <article>
        <p class="p">
          
        </p>
      </article>

      </section>


      <section class="container_vermietung">


      <article class="item_vermietung">
        <Link to="/bildtechnik"><img src="./images/beamerptdz770.png" alt="Vermietung" class="vermietung"/></Link> 
        <Link to="/bildtechnik"><h3 class="h3links">Bildtechnik</h3></Link> 
      </article>

      <article class="item_vermietung">
        <Link to="/lichttechnik"><img src="./images/movinghead.png" alt="Team" class="vermietung_rechts"/></Link> 
        <Link to="/lichttechnik"><h3 class="h3rechts">Lichttechnik</h3></Link>
      </article>

      <article class="item_vermietung">
        <Link to="/tontechnik"><img src="./images/jblgesamtset.png" alt="Vermietung" class="vermietung"/></Link>
        <Link to="/tontechnik"><h3 class="h3links">Tontechnik</h3></Link>
      </article>

      <article class="item_vermietung">
        <Link to="/buehnentechnik"><img src="./images/lift4m.png" alt="Team" class="vermietung_rechts"/></Link> 
        <Link to="/buehnentechnik"><h3 class="h3rechts">Bühnentechnik</h3></Link>
      </article>

      </section>
    </>
    
  )
}

export default Vermietung;