import logo from './logo.svg';
import { useState, useEffect } from 'react';
import Detailansicht from './components/Detailansicht';
import {BrowserRouter as Router, Routes, Route, Link, useParams } from 'react-router-dom';
import Navbar from './components/Navbar';
import Footer from './components/Footer';

import Lichttechnik from './pages/Lichttechnik';
import Lichtdetailansicht from './pages/Lichtdetailansicht';
import Tontechnik from './pages/Tontechnik';
import Tondetailansicht from './pages/Tondetailansicht';
import Bildtechnik from './pages/Bildtechnik';
import Bilddetailansicht from './pages/Bilddetailansicht';
import Buehnentechnik from './pages/Buehnentechnik';
import Buehnendetailansicht from './pages/Buehnendetailansicht';

import './components/style/Style.css';
import './components/style/Desktop.css';

import Home from './pages/Home';
import Vermietung from './pages/Vermietung';
import Ueberuns from './pages/Ueberuns';
import Kontakt from './pages/Kontakt';
import Impressum from './pages/Impressum';



function App() {

  var wert = 2;
/*
  <Router>
  <Navbar/>
    <Routes>
     
      
      
    </Routes>
    */

    //{isClicked && (<Detailansicht id={value}/>)}

    //<Route path="/detailansicht" element={<Detailansicht id={value}/>}/>

  return (
    <Router>
      <Navbar/>
      <Routes>
          <Route path="lichttechnik" element={<Lichttechnik/>}/>
          <Route path="lichttechnik/detailansicht/:paramsId" element={<Lichtdetailansicht id={wert}/>}/>
          <Route path="lichttechnik/detailansicht" element={<Lichtdetailansicht id={wert}/>}/>

          <Route path="tontechnik" element={<Tontechnik/>}/>
          <Route path="tontechnik/detailansicht/:paramsId" element={<Tondetailansicht id={wert}/>}/>
          <Route path="tontechnik/detailansicht" element={<Tondetailansicht id={wert}/>}/>

          <Route path="bildtechnik" element={<Bildtechnik/>}/>
          <Route path="bildtechnik/detailansicht/:paramsId" element={<Bilddetailansicht id={wert}/>}/>
          <Route path="bildtechnik/detailansicht" element={<Bilddetailansicht id={wert}/>}/>

          <Route path="buehnentechnik" element={<Buehnentechnik/>}/>
          <Route path="buehnentechnik/detailansicht/:paramsId" element={<Buehnendetailansicht id={wert}/>}/>
          <Route path="buehnentechnik/detailansicht" element={<Buehnendetailansicht id={wert}/>}/>

          <Route path='/' element={<Home/>}></Route>
          <Route path='/vermietung' element={<Vermietung/>}></Route>
          <Route path='/ueberuns' element={<Ueberuns/>}></Route>
          <Route path='/kontakt' element={<Kontakt/>}></Route>
          <Route path='/impressum' element={<Impressum/>}></Route>

          
          
          
        </Routes>
    
   
  
      <>


     

      

      
    </>

    <Footer/>
    </Router>
  );
}

export default App;
