import React from 'react'

import { useState, useEffect } from 'react';
import {BrowserRouter as Router, Routes, Route, Link, useParams } from 'react-router-dom';
import 'react-slideshow-image/dist/styles.css'
import { Slide } from 'react-slideshow-image';



function DetailSlideshow({found}) {


  var images = [];
  /*-------------------*/

    var imagesLength = Object.keys(found.images[0]).length;

    found.images.map(item =>{
        for(var i = 1; i < imagesLength+1; i++){
        if (window.location.hostname == "localhost"){
            images[i-1] = "https://" + window.location.hostname +":3000/images/" + item[i] + ".png"
        }
        else{
            // images[i-1] = "https://" + window.location.hostname +"/images/" + item[i] + ".png"
            images[i-1] = process.env.PUBLIC_URL +"/images/" + item[i] + ".png";
        }
        }
    })
 
    const inDicators = (index) => (<div className="indicator"></div>);
        
    const properties = {
      duration: 1500,
      autoplay: false,
      infinite: true,
      pauseOnHover: false,
      cssClass: "height: 50%",
      transitionDuration: 500,
      indicators: inDicators,
      prevArrow: <button><a class="prev">&#10094;</a></button>,
      nextArrow: <button><a class="next">&#10095;</a></button>,
    };
  


/*
  const images2 = [
    "https://images.unsplash.com/photo-1509721434272-b79147e0e708?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1500&q=80",
    "https://images.unsplash.com/photo-1506710507565-203b9f24669b?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1536&q=80",
    "https://images.unsplash.com/photo-1536987333706-fc9adfb10d91?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1500&q=80",
];

const images3 = ["images/glidecam.png", "images/keinbild.png","images/keinbild.png"]
*/


  return (
    <>
        
        <div class='vermietung_produkt'>
            <div style={{ width: "100%", height: "auto" }}>
                <Slide {...properties}>
                {images.map((each, index) => (
                    <img
                    style={{ objectFit: "contain", width: "100%" }}
                    src={each}
                    key={index}
                    alt={"Bild: "+ index}
                    />
                ))}
                </Slide>
            </div>
        </div>
        
    
    </>
  )
}


export default DetailSlideshow;