import React from 'react'
import Data from "../tondata.json";
import { useState, useEffect, useLayoutEffect } from 'react';
import Detailansicht from '../components/Detailansicht';

import {BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
import Navbar from '../components/Navbar';
import 'react-slideshow-image/dist/styles.css'
import { Slide } from 'react-slideshow-image';
//import ImageGallery from 'react-image-gallery';


function Tontechnik() {

  useLayoutEffect(() => {
    window.scrollTo(0, 0)
});


  
  const [isClicked, setIsClicked] = useState(false);
  const [value, setValue] = useState();

   /*json Data Variables*/
   var title;
   var img;

   var images = [];
   /*-------------------*/

   var dataMapCounter = 1;


  function searchItem(id){
    var found = Data.find(post => {
      return post.id === id;
    });
    console.log(found);
    console.log(id)

    setIsClicked(true)
    setValue(id); //found mitgeben???
  }


  /*greift beim public ordner auf die images zu !!!*/
  getImg();
  function getImg(){
    { Data.map((item, index) => {
      //images[index] = "../images/keinbild.png";
      if(item.img != ""){
        //images[index] = "../images/"+item.img+".png"
        images.push("./images/"+item.img+".png")
      }
      //console.log(images);
    })
  }
}

    //{isClicked && (<Detailansicht id={value}/>)}

    // <Link to='/detailansicht'><button onClick={() => searchItem(post.id)}></button></Link>

    //funkt. nicht keine ahnung wieso der Link nicht geöffnet wird {isClicked && (<Link to={`/detailansicht/${value}`}></Link>)}  

    /*
    
        {!isClicked &&(
      <>
      <h1>Welcome</h1>
      <div>
        { Data.map(post => {
          return(
            <>
             <div key={post.id}></div>
              <h4> {post.id} </h4>
              <h4>{ post.title }</h4>
              <button onClick={() => searchItem(post.id)}>Click</button>
            </>
          )
        })}
        
      </div>
      </>
    )}
      
    */

const properties = {
  duration: 2000,
  autoplay: true,
  infinite: true,
  pauseOnHover: false,
  cssClass: "height: 50%",
  transitionDuration: 500,
  prevArrow: <button><a class="prev">&#10094;</a></button>,
  nextArrow: <button><a class="next">&#10095;</a></button>,
};

/*greift beim public ordner auf die images zu !!!*/
//const srcImages = ["../images/keinbild.png", "../images/lift4m.png","../images/boomtonedj_neu.png", "../images/beamer_neu.png"];



  return (
        
        


    <>

      <div class="placeholder"></div>


      
      <p class="slidetitel">Tontechnik</p>
      <div class="slideshow-container">

        <div style={{ width: "100%", height: "auto" }}>
          <Slide {...properties}>
            {images.map((each, index) => (
              <img
                style={{ objectFit: "contain", width: "100%" }}
                src={each}
                key={index}
                alt={"Bild: "+ index}
              />
            ))}
          </Slide>
        </div>

      </div>


    
    <section>
      <article>
        <p class="p top-100">
          Hier siehst du unsere Produkte rund um die
        </p>
      </article>

      <article>
        <h1>
            Tontechnik
        </h1>
      </article>
    </section>


   
    <section class="container_vermietung">

        { Data.map(post => {
          img = "keinbild";
          dataMapCounter++;
          if(post.img != ""){
            img = post.img;
          }
          if(dataMapCounter%2){/*Zweiter Druchlauf*/
          return(
            <>
              <article class="item_vermietung">
                <Link to={`detailansicht/${post.id}`}><img src={`./images/${img}.png`} alt="Team" class="vermietung_rechts"/></Link> 
                <Link to={`detailansicht/${post.id}`}><h3 class="h3rechts">{post.title}</h3></Link> 
              </article>
            </>
          )
          }
          else{/*Erster Druchlauf*/
          return(
            <>
            <article class="item_vermietung">
                <Link to={`detailansicht/${post.id}`}><img src={`./images/${img}.png`} alt="Vermietung" class="vermietung"/></Link> 
                <Link to={`detailansicht/${post.id}`}><h3 class="h3links">{post.title}</h3></Link> 
              </article>
             
            </>
          )
          }
        })}
        </section>



    </>
    
  )
}

export default Tontechnik;